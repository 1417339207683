.App {
  text-align: center;
}

.chat-cont{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  }
.header{
  width: 100%;
  height: 60px;
  background: #59c4f3;
  display: flex;
  justify-content: space-between;
  }
.loader{
  margin-left: 20px;
  /*position: absolute;*/
  /*bottom: 80px;*/
  }
.content{
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /*justify-content: flex-end;*/
  
  scroll-behavior: smooth;
  }
.content ul{
  padding-inline-start: 20px
  } 
.content-out-user{
  display: flex;
  justify-content: flex-end;
  }
.content-out-assist{
  display: flex;
  justify-content: flex-start;
  }
.content-in{
  background: #e0f0f7;
  margin: 10px;
  padding: 20px;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 8px;
  font-size: 14px;
  max-width: 60%;
  }
.content-in-user{
  text-align: right;
  }
.content-in-assist{
  background: #b9eaff;
  }
.content textarea{
  margin-left: 20px;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #d4d2d2;
  border-radius: 6px;
  resize: none;
  box-sizing: border-box;
  width: calc(100% - 40px);
  min-height: 400px;
  }
.user-input{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  }
.user-input input{
  margin-left: 20px;
  margin-top: 16px;
  height: 30px;
  padding: 10px;
  border: 1px solid #d4d2d2;
  border-radius: 6px;
  width: calc(100% - 130px);
  font-size: 14px;
  }
.user-input input:focus {
  outline: 1px solid #59c4f3;
  }
.user-input .search-button{
  width: 50px;
  height: 50px;
  background: #59c4f3;
  margin-top: 16px;
  margin-right: 20px;
  border-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  cursor: pointer;
  }